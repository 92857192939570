import { Injectable } from '@angular/core';

import { SharedServicesProvider } from '../../core/customs/shared/shared-services-provider';
import { MainMenuItem } from '../../core/customs/shared/menus';
import { ViewModelBase } from '../infrastructure/view-model-base';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Injectable()
export class ShellViewModel extends ViewModelBase
{
    //--------------------------------------------------------------------------------------------
    // CONSTRUCTOR
    //--------------------------------------------------------------------------------------------
    constructor(
        private _router: Router,
        protected sharedServicesProvider: SharedServicesProvider,
    )
    {
        super(sharedServicesProvider);

        this._router.events
            .pipe(
                filter(event => event instanceof NavigationEnd && window.innerWidth <= 970)
            )
            .subscribe(() => {
                const menuBtnNav = document.getElementById('menu-btn') as HTMLInputElement;

                if (menuBtnNav && menuBtnNav.checked) {
                    menuBtnNav.checked = false;
                }
            });
    }

    //--------------------------------------------------------------------------------------------
    // PROPERTIES
    //--------------------------------------------------------------------------------------------
    
    //--------------------------------------------------------------------------------------------
    // OVERRIDE
    //--------------------------------------------------------------------------------------------
    public AfterViewModelInit()
    {
        super.AfterViewModelInit();
    }

    //--------------------------------------------------------------------------------------------
    // COMMAND HANDLERS
    //--------------------------------------------------------------------------------------------
    public OnSettingLanguage(lang: string)
    {
        this.SetLanguage(lang);
    }

    //--------------------------------------------------------------------------------------------
    // PRIVATE METHODS
    //--------------------------------------------------------------------------------------------
    private Navigate(href: string) {
        if(window.innerWidth <= 970)
        {
            this.sharedServicesProvider.document.getElementById('menu-btn').checked = false;
            this.sharedServicesProvider.document.getElementById('close-btn').checked = true;
        }

        this.NavigateTo([href]);
    }
}
