import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, NgOptimizedImage, PercentPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from "@angular/material/menu";
import { TranslateModule } from '@ngx-translate/core';

import { ControlsModule } from '../ui/custom/controls/controls.module';
import { ElementsModule } from './custom/elements/elements.module';

import { UIRoutes } from './ui.routing';

import { MessageNotifier } from '../core/customs/shared/message-notifier';
import { SharedServicesProvider } from '../core/customs/shared/shared-services-provider';
import { AppState } from '../core/customs/shared/app-state';
import { ShellComponent } from './shell/shell.component';
import { UIComponent } from './ui.component';

import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDialogModule } from '@angular/material/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';

import { HomeComponent } from './home/home.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ScentExperienceComponent } from './experiences/scent/scent.component';
import { AudioExperienceComponent } from './experiences/audio/audio.component';
import { VideoExperienceComponent } from './experiences/video/video.component';
import { ContactComponent } from './contact/contact.component';
import { SessionComponent } from './session/session.component';
import { FAQComponent } from './faq/faq.component';
import { ActivateUserComponent } from './session/activate-user/activate-user.component';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MusicRequestComponent } from './forms/music-request/music-request.component';
import { ProfileComponent } from './account/profile/profile.component';

@NgModule(
    {
        imports:
            [
                RouterModule.forChild(UIRoutes),
                
                CommonModule,
                MatMenuModule,
                MatTooltipModule,
                MatSidenavModule,
                MatCardModule,

                TranslateModule,
                
                ControlsModule,
                ElementsModule,

                MatProgressBarModule,
                MatProgressSpinnerModule,
                MatCheckboxModule,
                MatSnackBarModule,
                MatButtonModule,
                MatTooltipModule,
                MatSlideToggleModule,
                MatSliderModule,
                MatTableModule,
                MatToolbarModule,
                MatTableModule,
                MatDialogModule,
                ReactiveFormsModule,
                FormsModule,
                MatFormFieldModule,
                MatRadioModule,
                MatInputModule,
                MatNativeDateModule,
                MatSelectModule,

                FlexLayoutModule,

                NgOptimizedImage,
            ],
        declarations:
            [
                UIComponent,
                ShellComponent,

                HomeComponent,
                AboutUsComponent,
                ScentExperienceComponent,
                AudioExperienceComponent,
                VideoExperienceComponent,
                ContactComponent,
                SessionComponent,
                FAQComponent,
                ActivateUserComponent,
                
                MusicRequestComponent,
                ProfileComponent
            ],
        exports:
            [
                UIComponent,
                RouterModule,

                HomeComponent,
                AboutUsComponent,
                ScentExperienceComponent,
                AudioExperienceComponent,
                VideoExperienceComponent,
                ContactComponent,
                SessionComponent,
                FAQComponent,
                ActivateUserComponent,

                MusicRequestComponent
            ],
        providers:
            [
                CurrencyPipe,
                PercentPipe,

                MessageNotifier,
                SharedServicesProvider,
                AppState
            ]
    }
)
export class UIModule
{
}