<div class="app">

  <!-- * Header (Nav) -->
  
  <nav>
    <div class="wrapper">

      <div class="menu-icon-logo-container">
        <div class="logo"><a (click)="vm.Navigate('/')"><img class="logo-img" src="assets/images/logos/zuni/white-black.jpg" alt="{{'ALT_ZUNI_LOGO_COLOR' | translate}}"/></a></div>
        <label for="menu-btn" class="btn menu-btn"><span class="material-symbols-outlined icon">menu</span></label>
      </div>

      <input type="radio" name="slider" id="menu-btn">
      <input type="radio" name="slider" id="close-btn">

      <ul class="nav-links">
        <div class="logo-home"><a (click)="vm.Navigate('/')"><img class="logo-img" src="assets/images/logos/zuni/white-black.jpg" alt="{{'ALT_ZUNI_LOGO_COLOR' | translate}}"/></a></div>
        <label for="close-btn" class="btn close-btn"><span class="material-symbols-outlined icon">close</span></label>

        <div class="divider-bar mobile-item"></div>

        <li>
          <a class="custom-anchor-sidenav uppercase" (click)="vm.Navigate('/')">{{'LABEL_HOME' | translate}} <span class="material-symbols-outlined icon navigate-arrow">navigate_next</span></a>
          <a class="desktop-item uppercase" routerLink="/">{{'LABEL_HOME' | translate}}</a>
        </li>

        <li class="w-about-us">
          <a class="custom-anchor-sidenav uppercase" (click)="vm.Navigate('/about-us')">{{'LABEL_ABOUT_US' | translate}} <span class="material-symbols-outlined icon navigate-arrow">navigate_next</span></a>
          <a class="desktop-item uppercase" routerLink="about-us">{{'LABEL_ABOUT_US' | translate}}</a>
        </li>

        <li>
          <input type="checkbox" id="experiencesMenu">
          <label for="experiencesMenu" class="mobile-item uppercase">{{'LABEL_EXPERIENCES' | translate}} <span class="material-symbols-outlined icon navigate-arrow">keyboard_arrow_down</span></label>
          <p class="p-nav desktop-item uppercase">{{'LABEL_EXPERIENCES' | translate}}</p>
          <ul class="drop-menu">
            <li><a class="uppercase" (click)="vm.Navigate('experiences/scent')">{{'LABEL_EXPERIENCE_SCENT' | translate}} <span class="material-symbols-outlined icon navigate-arrow icon-mobile">navigate_next</span></a></li>
            <li><a class="uppercase" (click)="vm.Navigate('experiences/audio')">{{'LABEL_EXPERIENCE_AUDIO' | translate}} <span class="material-symbols-outlined icon navigate-arrow icon-mobile">navigate_next</span></a></li>
            <li><a class="uppercase" (click)="vm.Navigate('experiences/video')">{{'LABEL_EXPERIENCE_VIDEO' | translate}} <span class="material-symbols-outlined icon navigate-arrow icon-mobile">navigate_next</span></a></li>
          </ul>
        </li>

        <li>
          <a class="custom-anchor-sidenav uppercase" (click)="vm.Navigate('contact')">{{'LABEL_CONTACT' | translate}} <span class="material-symbols-outlined icon navigate-arrow">navigate_next</span></a>
          <a class="desktop-item uppercase" routerLink="contact">{{'LABEL_CONTACT' | translate}}</a>
        </li>

        <li>
          <a class="custom-anchor-sidenav uppercase" (click)="vm.Navigate('faq')">{{'LABEL_FAQ' | translate}} <span class="material-symbols-outlined icon navigate-arrow">navigate_next</span></a>
          <a class="desktop-item uppercase" routerLink="faq">{{'LABEL_FAQ' | translate}}</a>
        </li>

        <li class="icon-btn translate-icon-mobile">
          <input type="checkbox" id="languagesMenu">
          <label for="languagesMenu" class="mobile-item"><img src="../../../assets/icons/translate.svg" alt="Translate icon"/></label>
          <a class="desktop-item"><img src="../../../assets/icons/translate.svg" alt="Translate icon"/></a>
          <ul class="drop-menu translate-menu">
            <li><a (click)="vm.OnSettingLanguage('es-MX')">{{'LABEL_LANGUAGE_SPANISH' | translate}} - 🇲🇽</a></li> <!-- * Iconos momentaneos... -->
            <li><a (click)="vm.OnSettingLanguage('en-US')">{{'LABEL_LANGUAGE_ENGLISH' | translate}} - 🇺🇸</a></li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>

  <!-- * Floating Nav - Custom... (Responsive) -->

  <main class="main">
    <div class="main-content">
      <router-outlet></router-outlet>
    </div>
  </main>

  <!-- * Footer -->
  
  <footer class="footer">
    <div class="footer-container">
      <div class="container zuni-data-container">
        <div class="row zuni-data">
          <div class="zuni-logo col-lg-3 col-md-3 col-sm-6 col-xs-12">
            <a routerLink="/">
              <img src="assets/images/logos/zuni/logo-footer.jpg" alt="{{'ALT_ZUNI_LOGO_WHITE' | translate}}">
            </a>
          </div>
          <div class="col-lg-2 col-md-2 col-sm-6 col-xs-12">
            <div>
              <ul class="pages-list">
                <li><a routerLink="/" class="uppercase">{{'LABEL_HOME' | translate}}</a></li>
                <li><a routerLink="about-us" class="uppercase">{{'LABEL_ABOUT_US' | translate}}</a></li>
                <li><a routerLink="experiences/scent" class="uppercase">{{'LABEL_EXPERIENCE_SCENT' | translate}}</a></li>
                <li><a routerLink="experiences/audio" class="uppercase">{{'LABEL_EXPERIENCE_AUDIO' | translate}}</a></li>
                <li><a routerLink="experiences/video" class="uppercase">{{'LABEL_EXPERIENCE_VIDEO' | translate}}</a></li>
                <li><a routerLink="contact" class="uppercase">{{'LABEL_CONTACT' | translate}}</a></li>
                <li><a routerLink="faq" class="uppercase">{{'LABEL_FAQ' | translate}}</a></li>
              </ul>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
            <div class="zuni-contact-data">
              <p>{{'LABEL_SUPPORT' | translate}}: <br>
                <a href="tel:{{'ZUNI_PHONE_URL' | translate}}" class="f-m-el">{{'ZUNI_PHONE' | translate}}</a>
                <br>
                <a href="mailto:{{'ZUNI_EMAIL' | translate}}" class="f-m-el">{{'ZUNI_EMAIL' | translate}}</a>
                <br>
                <br>
                {{'LABEL_ADDRESS' | translate}}: <br>
                <a href="https://maps.app.goo.gl/4XHMPXoKrx572cgK7" target="_blank" class="f-m-el">{{'ZUNI_ADDRESS' | translate}}</a>
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
            <div class="zuni-icons-links">
              <div>
                <a href="https://www.facebook.com/ZUNImexico" target="_blank"><span class="fa-brands fa-facebook"></span></a>
                <a href="https://www.linkedin.com/showcase/zunimexico/" target="_blank"><span class="fa-brands fa-linkedin"></span></a>
                <a href="https://www.instagram.com/zunimexico/" target="_blank"><span class="fa-brands fa-instagram"></span></a>
              </div>
              <div>
                <a href="https://twitter.com/ZUNImexico" target="_blank"><span class="fa-brands fa-x-twitter"></span></a>
                <a href="https://www.youtube.com/channel/UCNZVTO8le_iNwdAfXJuR6zQ" target="_blank"><span class="fa-brands fa-youtube"></span></a>
                <a href="https://open.spotify.com/user/iekrgc0vjv5lib47cvwurels0?si=9bfb1c972ff14f24" target="_blank"><span class="fa-brands fa-spotify"></span></a>
              </div>
            </div>
            <div class="payment-methods">
              <p class="uppercase">Métodos de pago aceptados:</p>
              <div class="payment-methods-icons">
                <img src="../../../assets/icons/visa.svg" alt="">
                <img src="../../../assets/icons/mastercard.svg" alt="">
                <img src="../../../assets/icons/amex.svg" alt="">
                <img src="../../../assets/icons/paypal.svg" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="zuni-legal-info">
      <div>
        <p class="uppercase">{{'LABEL_COPYRIGHT' | translate}} | {{'LABEL_ATR_PROPERTY' | translate}} | 
          <a class="uppercase" class="footer-link" href="https://atr.com.mx/aviso-de-seguridad-y-privacidad/" target="_blank">{{'LABEL_SECURITY_AND_PRIVACY' | translate}}</a>
        </p>
      </div>
    </div>
  </footer>

</div>